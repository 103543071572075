<template>
	<div class="ysm-order-fangan">
		<!-- 第一部分 -->
		<div class="ordertop">
			<div class="con">
				<div class="conleft">
					<!-- <span class="oidclass">订&nbsp;单&nbsp;ID&nbsp;号:&nbsp;&nbsp;&nbsp;&nbsp;{{ruleForm.oid}}</span> -->
					<span class="oidclass">订&nbsp;单&nbsp;ID&nbsp;号:&nbsp;&nbsp;&nbsp;&nbsp;--</span>
				</div>
				<div class="conright">
					 <el-table
					      :data="tableData"
						  style="width: 80%;height: 316.8px;border: none;margin: 0px auto;overflow-y: auto;margin-bottom: 20px;"
						  class="customer-no-border-table"
					    >
						   <el-table-column>
							    <template slot-scope="scope">
							 <!-- <a :href="scope.row.link" :download="scope.row.link.substring()"><img src="../../assets/ysmimg/fangan.png" /></a> -->
							 
							 <img @click="download(scope.row.link)" src="../../assets/ysmimg/fangan.png" />
							  </template>
						   </el-table-column>
					      <el-table-column
					        prop="title"
					       >
					      </el-table-column>
					      <el-table-column
					        prop="time1"
					      >
					      </el-table-column>
					      <el-table-column>
						   <template slot-scope="scope">
							   <el-input v-if="scope.row.answer!=null" :value="scope.row.answer" ></el-input>
							   <el-input v-if="scope.row.answer==null" v-model="answer" placeholder="请填写建议" ></el-input>
							  <!-- <p><el-checkbox v-if="scope.row.answer==null" v-model="tongjichecked" @change="tongyi">同意且订单不可撤销</el-checkbox></p>
								<p><el-checkbox v-if="scope.row.answer==null" v-model="butongyichecked" @change="butongyi">不同意并填写意见</el-checkbox></p> -->
						   </template>
					      </el-table-column>
						  <el-table-column
						    prop="result"
						  >
						   <template slot-scope="scope">
							   <el-checkbox v-if="scope.row.answer==null" v-model="tongjichecked" @change="tongyi(scope.row.id)">同意且订单不可撤销</el-checkbox>
							    <span v-if="scope.row.answer!=null">{{scope.row.result}}</span>
							 </template>   
						  </el-table-column>
						  <el-table-column
						    prop="time2"
						  >
						  <template slot-scope="scope">
						  <el-checkbox v-if="scope.row.answer==null" v-model="butongyichecked" @change="butongyi(scope.row.id)">不同意并填写意见</el-checkbox>
						<span v-if="scope.row.answer!=null">{{scope.row.time2}}</span>
						</template>
						 </el-table-column>
					    </el-table>
						<div v-if="xianshi==true" align="right">
						    <el-button @click="tijiao" class="nextStep" >提交</el-button>
						</div>
				</div>
			</div>
		</div>	
		
			
		<div class="f">Copyright&copy;www.techlion.com.cn All Rights Reserved<br/>山东泰克莱恩 0513-88762550</div>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui';
	export default {
		data: function() {
			return {
				
				answer:'',
				xianshi:'',
				tid:'',
				ruleForm: {
					oid: ""
				},
				jihua:[],
				stepIndex: 0,
				tableData:[],
				tongjichecked:false,
				butongyichecked:false
			}
		},
		methods: {
			//下载
			download(link){
				
				// this.$axios.get("http://120.26.66.95:8088/client/order/xiazaiFangan?link="+link
				// ).then(res => {
				// 	if(res.data.code==200){
				// 		// this.$alert('您已经同意', '提示', {
				// 		// 	confirmButtonText: '确定',
				// 		// 	callback: action => {
				// 		// 		this.$router.push("/ysmmain/ysmlatestorder");
				// 		// 	}
				// 		// });
				// 	}
					
				
				// }).catch(err => {
				// 	this.$alert('请联系管理员', '提示', {
				// 		confirmButtonText: '确定',
				// 		callback: action => {
							
				// 		}
				// 	});
				// 	//this.$router.push("/ysmmain/ysmlatestorder")
				// });
				// window.open("http://120.26.66.95:8088/client/order/xiazaiFangan?link="+link);
				location.href="http://120.26.66.95:8088/client/order/xiazaiFangan?link="+link;
			},
			//同意
			tongyi(id){
				this.tid=id;
				this.butongyichecked=false;
				this.answer="";
				
			},
			//不同意
			butongyi(id){
				this.tid=id;
				this.tongjichecked=false;
				if(this.answer==""){
					this.$alert('请填写建议', '提示', {
						confirmButtonText: '确定',
						callback: action => {
						}
					});
				}
			},
			//提交意见
			tijiao(){
				if(this.tongjichecked){
					this.$axios.get("/client/order/tongyifangan", {
						params: {
							fid:this.tid
						}
					}).then(res => {
						if(res.data.code==200){
							this.$alert('您已经同意', '提示', {
								confirmButtonText: '确定',
								callback: action => {
									this.$router.push("/ysmmain/ysmlatestorder");
								}
							});
						}
						
					
					}).catch(err => {
						this.$alert('请联系管理员', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								
							}
						});
						//this.$router.push("/ysmmain/ysmlatestorder")
					});
				}else{
					this.$axios.get("/client/order/butongyifangan", {
						params: {
							fid:this.tid,
							answer:this.answer
						}
					}).then(res => {
						if(res.data.code==200){
							this.$alert('提交成功', '提示', {
								confirmButtonText: '确定',
								callback: action => {
									this.$router.push("/ysmmain/ysmlatestorder");
								}
							});
						}
						
					
					}).catch(err => {
						this.$alert('请联系管理员', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								
							}
						});
						//this.$router.push("/ysmmain/ysmlatestorder")
					});
				}
				
				
			},
			//确认收货
			confirmGoods() {
				this.$axios.get("/client/order/completeOrder", {
					params: {
						oid: this.ruleForm.oid
					}
				}).then(res => {
					if(res.data.code==200){
						this.$alert('确认收货成功', '提示', {
							confirmButtonText: '确定',
							callback: action => {
							}
						});
					}
					

				}).catch(err => {
					this.$message({
						type: "error",
						message: "请联系管理员",
						duration: 1000,
						offset: 300
					});
					//this.$router.push("/ysmmain/ysmlatestorder")
				});
			}


		},
		mounted: function() {

		},
		created: function() {
			this.ruleForm.oid = sessionStorage.getItem("oid");
			this.$axios.get("/client/order/chaxunfangan", {
				params: {
					oid: this.ruleForm.oid
				}
			}).then(res => {
				console.log(res.data);
				this.tableData = res.data.data.jihua;
				this.xianshi=res.data.data.xianshi;
			}).catch(err => {
				this.$message({
					type: "error",
					message: "请联系管理员",
					duration: 1000,
					offset: 300
				});
				//this.$router.push("/ysmmain/ysmlatestorder")
			});
			// this.conditionForm.doctorId = JSON.parse(sessionStorage.getItem("loginInfo")).id;

			//将当前路径存储到backLocation
			this.$store.commit("setBackLocation", "/ysmmain/ysmlatestorder");
		}
	}
</script>

<style scoped="scoped">
	/* 按钮 */
	.nextStep {
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
		margin-right: 130px;
		
	}
	.conright{
		
		
	}
	.ysm-order-fangan {
		width: 100%;
		height: 100%;
		background-color: transparent;
		overflow-y: auto;
		position: relative;
		margin-bottom: 1.875rem;

	}

	.ordertop {
		margin-top: 18.8rem;
		min-height: 500px;
		background-color: white;
		padding-top: 0.5rem;
		margin-bottom: 190px;
	}

	.order {
		position: relative;
		background-color: white;
		width: 100%;
		height: 31.75rem;
		/* margin-top: 18.75rem; */
		margin-top: 1.5rem;
		margin-bottom: 38.75rem;
		padding-top: 0.5rem;
		display: flex;
	}

	.con {
		width: 90%;
		height: 100%;
		border-top: 5px solid #af9f73 !important;
		margin: 0px auto;

	}

	.conleft {
		float: left;
		text-align: left;
		width: 350px;
		margin-left: 130px;
		margin-bottom: 10px;
	}

	
	

	.orderbottomleft {
		height: 100%;
		width: 350px;
		margin-left: 4.25rem;
		
	}

	.confirmGoods {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 340px;
		height: 120px;
		background-color: #F0F0EE;
		border-radius: 5px;
		white-space: pre-wrap;
		margin-top: 50px;
	}

	/* 按钮 */
	.nextStep {
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
		margin-top: 10px;
	}

	/* 订单进程记录 */
	.orderbottomright {
		width: 65%;
		margin-left: 6.25rem;
	}

	.orderJinChengInfo {
		font-weight: bolder;
		border-bottom: 3px solid #af9f73;
		padding-bottom: 5px;
		margin-bottom: 5px;
	}

	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 35px;
	}


	.primary {
		margin: 0px 0px 1px;
		padding: 6px 12px 6px 12px;
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		border-radius: 0px;
		width: 5.125rem;
		height: 2.3rem;
	}
	.orderbottomleft table td{
		
		text-align: left;
		padding: 0px;
		display: inline-block;
		width: 100px;
		
	}
	.orderbottomleft table .td1{
		width: 230px;
	}
	
	.jindutable tr td{
		width: 3px;
		line-height: 30px;
	}
	.oidclass{
		display: inline-block;
		height: 40px;
		line-height: 40px;
		background-color:#af9f73 ;
		border-radius: 15px;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
		margin-top: 20px;
		color: white;
	}
	.f {
		position: relative;
		background-color: #444;
		padding: 10px 0 10px 0;
		color: #fff;
		bottom: 0rem;
	}
	
</style>
